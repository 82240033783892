.orange_regular_button{
    border: none;
    background: var(--accent-app-color);
    padding: 13px 23px;
    border-radius: 32px;
    margin: 0;
    cursor: pointer;
    transition: scale 0.15s ease, background 0.15s ease;
}
.orange_regular_button_text{
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
}
.orange_regular_button:hover{
    background: var(--accent-hover-app-color);
}
.orange_regular_button.deact{
    background: var(--deactived-opacity-color);
    color:var(--negative-color);
}
.orange_regular_button:active{
    scale:0.95;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {

}
@media screen and (min-width: 767px) and (max-width: 1024px) {

}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .orange_regular_button{
        padding: 12px 20px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .orange_regular_button{
        padding: 10px 18px;
    }
    .orange_regular_button_text{
        font-size: 0.75rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .orange_regular_button{
        padding: 10px 18px;
    }
    .orange_regular_button_text{
        font-size: 0.75rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .orange_regular_button{
        padding: 10px 18px;
    }
    .orange_regular_button_text{
        font-size: 0.75rem;
    }
}