.button_white{
    border: none;
    background: #fff;
    font-size: 1.25rem;
    color: #000;
    padding: 8px;
    border-radius: 20px;
    width: 156px;
    text-align: center;
    cursor: pointer;
    margin-top: 28px;
    transition: background 0.15s ease,scale 0.15s ease;
}
.button_white:hover{
    background: #c6c6c6;
}
.button_white:active{
    scale:0.95;
}
.button_white.deact{
    opacity:40%;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .button_white{
        width: 146px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .button_white{
        width: 136px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .button_white{
        width: 120px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .button_white{
        margin-top: 5px;
        width: 95px;
        font-size: 1rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .button_white{
        margin-top: 8px;
        width: 95px;
        font-size: 0.98rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .button_white{
        margin-top: 8px;
        width: 95px;
        font-size: 0.98rem;
    }
}