.modal_confirm_subtitle{
    color: var(--subtext-color);
    width: 100%;
    margin: 11px 0;
    text-align: left;
}
.modal_confirm_total{
    font-size: 1.15rem;
    color: var(--accent-app-color);
}
.modal_confirm_text{
    width: 100%;
    margin: 0;
    font-size: 0.9rem;
    color: var(--negative-color);
}