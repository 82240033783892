.orders_items_area {
    justify-content: start;
}
.orders_empty{

}
.orders_empty_img{
    height: 200px;
}
.orders_empty_text{
    margin-top: 20px;
    font-size: 1.5rem;
    color: var(--negative-color);
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .orders_empty_img{
        height: 180px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .orders_empty_img{
        height: 160px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .orders_empty_img{
        height: 140px;
    }
    .orders_empty_text{
        margin-top: 5px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .orders_empty_img{
        height: 140px;
    }
    .orders_empty_text{
        margin-top: 5px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .orders_empty_img{
        height: 140px;
    }
    .orders_empty_text{
        margin-top: 5px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .orders_empty_img{
        height: 120px;
    }
    .orders_empty_text{
        margin-top: 5px;
    }
}