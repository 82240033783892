.map_widget{
    width: 100%;
    height: 232px;
    overflow: hidden;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.map_widget_img{
    position:absolute;
    width:85%;
    height: 232px;
    object-fit: cover;
    border-radius: 20px;
}
.map_widget_row{
    display:flex;
    justify-content: center;
}
.map_widget_txtaddress{
    color: #ffffff4f;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 7px;
    z-index: 1;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .map_widget,
    .map_widget_img{
        height: 200px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .map_widget,
    .map_widget_img{
        height: 200px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .map_widget,
    .map_widget_img{
        height: 190px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .map_widget,
    .map_widget_img{
        height: 185px;
    }
    .map_widget_img{
        width:95%;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .map_widget,
    .map_widget_img{
        height: 185px;
    }
    .map_widget_img{
        width:95%;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .map_widget,
    .map_widget_img{
        height: 185px;
    }
    .map_widget_img{
        width:95%;
    }
}