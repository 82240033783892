.modalbkg{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 11;
    backdrop-filter: grayscale(1);
    transition: background 0.4s ease;
}
.modal{
    background: var(--modal-bkg-color);
    width: 840px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    top: 0;
    transition: top 0.4s ease;
}
.modal_top{
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: flex-end;
    padding: 0 25px;
    align-items: center;
    box-shadow: -1px 7px 13px 0 var(--box-shadow-color);
}
.modal_body{
    width: 100%;
    max-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    overflow-y: auto;
}
.modal_bottom{
    height: 93px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_top_button{
    border: none;
    background: none;
    color: var(--accent-app-color);
    font-size: 1.05rem;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.15s ease;
}
.modal_top_button:hover{
    color: var(--accent-hover-app-color);
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .modal_top{
        height: 60px;
    }
    .modal_body{
        max-height: 67vh;
    }
    .modal_bottom{
        height: 70px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .modal{
        width: 610px;
    }
    .modal_top{
        height: 55px;
    }
    .modal_body{
        max-height: 60vh;
    }
    .modal_bottom{
        height: 60px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .modal{
        width: 610px;
    }
    .modal_top{
        height: 55px;
    }
    .modal_body{
        max-height: 60vh;
    }
    .modal_bottom{
        height: 60px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .modal{
        width: 100%;
    }
    .modal_top{
        height: 55px;
    }
    .modal_body{
        flex-wrap: nowrap;
        flex-direction: column;
        max-height: 68vh;
    }
    .modal_bottom{
        height: 60px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .modal{
        width: 100%;
    }
    .modal_top{
        height: 55px;
    }
    .modal_body{
        flex-wrap: nowrap;
        flex-direction: column;
        max-height: 68vh;
    }
    .modal_bottom{
        height: 60px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .modal{
        width: 100%;
    }
    .modal_top{
        height: 55px;
    }
    .modal_body{
        flex-wrap: nowrap;
        flex-direction: column;
        max-height: 68vh;
        padding: 5px;
    }
    .modal_bottom{
        height: 60px;
    }
}

.modal_anim-enter {
    background: rgba(0, 0, 0, 0);
    backdrop-filter: grayscale(0);
}
.modal_anim-enter > div:first-child {
    opacity: 0;
    scale: 0.9;
}

.modal_anim-enter-active {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: grayscale(1);
    transition: background 150ms,backdrop-filter 150ms;
}
.modal_anim-enter-active > div:first-child {
    opacity: 1;
    scale: 1;
    transition: opacity 150ms, scale 100ms;
}

.modal_anim-exit {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: grayscale(1);
}
.modal_anim-exit > div:first-child {
    opacity: 1;
    scale: 1;
}

.modal_anim-exit-active {
    background: rgba(0, 0, 0, 0);
    backdrop-filter: grayscale(0);
    transition: background 150ms,backdrop-filter 150ms;
}
.modal_anim-exit-active > div:first-child {
    opacity: 0;
    scale: 0.9;
    transition: opacity 150ms, scale 100ms;
}