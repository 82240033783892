.modal_check_button{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    align-items: center;
    cursor: pointer;
    transition: background 0.2s ease, scale 0.2s ease;
}
.modal_check_button.requare{
    opacity:50%;
}
.modal_check_button:hover{
    background:var(--hover-color);
}
.modal_check_button:active{
    scale:0.98;
}
.modal_check_button_left{
    display: flex;
    align-items: center;
}
.modal_check_button_img{
    height: 49px;
    width: 49px;
    object-fit: contain;
    margin-right: 7px;
}
.modal_check_button_name{
    font-size: 1rem;
    color: var(--negative-color);
}
.modal_check_button_subtext{
    font-size: 0.7rem;
    color: var(--subtext-color);
    margin-left: 10px;
}
.modal_check_button_check{
    border-radius: 24px;
    border: 1px solid var(--subtext-color);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s ease;
}
.modal_check_button_check.act{
    background:var(--accent-app-color);
}
.modal_check_button_check_img{
    width: 36px;
    opacity: 0;
}
.modal_check_button_check.act .modal_check_button_check_img{
    opacity:100%;
}