.cart_item{
    width: 100%;
    padding: 0 27px;
    display: flex;
    justify-content: space-between;
}
.cart_item_side{
    display: flex;
}
.cart_item_img{
    --size:63px;
    width: var(--size);
    height: var(--size);
    object-fit: contain;
}
.cart_item_center{
    margin-left: 13px;
    user-select: text;
}
.cart_item_center_title{
    font-size: 0.9rem;
    text-align: left;
    color: var(--negative-color);
}
.cart_item_center_price{
    font-size: 0.8rem;
    text-align: left;
    font-weight: 700;
    user-select: text;
    color: var(--negative-color);
}
.cart_item_center_additionals{
    font-size: 0.8rem;
    color: var(--subtext-color);
    text-align: left;
}
.cart_item_actions{
    display: none;
    border-radius: 32px;
    color: var(--negative-color);
    background: none;
    align-items: center;
    justify-content: center;
}
.cart_item_actions.act{
    display: flex;
}
.cart_item_actions_counter{
    margin: 0 12px;
    font-size: 1.15rem;
}
.cart_item_actions_button{
    background: var(--gray-button-color);
    border: none;
    color: var(--negative-color);
    cursor: pointer;
    border-radius: 12px;
    padding: 7px;
    transition: background 0.25s ease, scale 0.25s ease;
}
.cart_item_actions_button:hover{
    background: var(--gray-button-hover-color);
}
.cart_item_actions_button:active{
    scale:0.92;
}
.cart_item_actions_button_icon{
    width: 27px;
}
.cart_item_red_warning{
    color: #ff0000;
    text-align: left;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .cart_item_img {
        --size:55px;
    }
    .cart_item_actions_counter{
        margin: 0 8px;
    }
    .cart_item_actions_button {
        padding: 5px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .cart_item_img {
        --size:55px;
    }
    .cart_item_actions_counter{
        margin: 0 8px;
    }
    .cart_item_actions_button {
        padding: 5px;
    }
    .cart_item_actions_button_icon {
        width: 22px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .cart_item_img {
        --size:55px;
    }
    .cart_item_actions_counter{
        margin: 0 8px;
    }
    .cart_item_actions_button {
        padding: 5px;
    }
    .cart_item_actions_button_icon {
        width: 22px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .cart_item_img {
        --size:55px;
    }
    .cart_item_actions_counter{
        margin: 0 8px;
    }
    .cart_item_actions_button {
        padding: 5px;
    }
    .cart_item_actions_button_icon {
        width: 22px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .cart_item_img {
        --size:55px;
    }
    .cart_item_actions_counter{
        margin: 0 8px;
    }
    .cart_item_actions_button {
        padding: 5px;
    }
    .cart_item_actions_button_icon {
        width: 22px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .cart_item_img {
        --size:55px;
    }
    .cart_item_actions_counter{
        margin: 0 8px;
    }
    .cart_item_actions_button {
        padding: 5px;
    }
    .cart_item_actions_button_icon {
        width: 22px;
    }
}