footer{
    width: 100%;
    background: linear-gradient(180deg, rgba(38, 38, 38, 1) 0%, rgb(35 35 35) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0 30px;
}
footer .author{
    height: 68px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    width: 100%;
    border-bottom: 1px solid #484848;
}
footer .row{
    display: flex;
    justify-content: start;
    width: 100%;
}
footer .text{
    color: #5b5b5b;
    font-size: 0.7rem;
    user-select: text;
    margin: 30px 0;
}
footer .text_link{
    color: #929292;
    margin-left: 5px;
    cursor: pointer;
    padding: 2px;
    border-radius: 20px;
    transition: opacity 0.3s ease,scale 0.3s ease,background 0.3s ease;
}
footer .text_link:hover{
    background: rgba(146, 146, 146, 0.09);
    opacity: 0.8;
}
footer .text_link:active{
    scale: 0.98;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    footer .author{
        height: 50px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    footer .author{
        height: 45px;
    }
    footer .text{
        margin: 15px 0;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    footer{
        padding: 0 20px;
    }
    footer .author{
        height: 42px;
    }
    footer .text{
        margin: 15px 0;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    footer{
        padding: 0 20px;
    }
    footer .author{
        height: 50px;
    }
    footer .text{
        margin: 15px 0;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    footer{
        padding: 0 20px;
    }
    footer .author{
        height: 50px;
    }
    footer .text{
        margin: 15px 0;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    footer{
        padding: 0 15px;
    }
    footer .author{
        height: 45px;
    }
    footer .text{
        margin: 15px 0;
    }
}