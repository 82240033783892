
.loading_icon{
    height: 30px;
    animation: 0.9s linear infinite icon_loading_anim;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .loading_icon{
        height: 28px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .loading_icon{
        height: 26px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .loading_icon{
        height: 24px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .loading_icon{
        height: 22px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .loading_icon{
        height: 22px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .loading_icon{
        height: 21px;
    }
}

@keyframes icon_loading_anim {
    from {
        rotate:0deg;
    }
    to {
        rotate:360deg;
    }
}