.menu_block_sectionList_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    width: 252px;
    height: 358px;
    background: var(--modal-bkg-color);
    border-radius: 20px;
    box-shadow: 0 2px 10px 0 var(--box-shadow-color);
    cursor:pointer;
    transition: scale 0.15s ease,background 0.15s ease;
}
.menu_block_sectionList_item:hover{
    background: var(--hover-color);
}
.menu_block_sectionList_item:active{
    scale:0.98;
}
.menu_block_sectionList_item.deact{
    opacity: 0.4;
}
.menu_block_sectionList_item_center{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;
}
.menu_block_sectionList_item_img{
    height: 228px;
    width: 100%;
    object-fit: contain;
    padding: 31px 20px;
}
.menu_block_sectionList_item_title{
    font-size: 1rem;
    height: 70px;
    text-align:center;
    overflow: hidden;
    color: var(--negative-color);
}
.menu_block_sectionList_item_downDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px;
    align-items: center;
}
.menu_block_sectionList_item_downDiv_price{
    font-weight: 700;
    font-size: 1rem;
    color: var(--negative-color);
}
.menu_block_sectionList_item_downDiv_price.missing{
    display: none;
}
.menu_block_sectionList_item_downDiv_addToCartBtn{
    display:block;
    font-size: 0.75rem;
    border: none;
    background: var(--deactived-opacity-color);
    padding: 10px 19px;
    border-radius: 32px;
    color: var(--negative-color);
    text-decoration: none;
    cursor: pointer;
    transition: scale 0.15s ease,background 0.15s ease;
}
.menu_block_sectionList_item_downDiv_addToCartBtn.act{
    background: var(--accent-app-color);
    color: #fff;
}
.menu_block_sectionList_item_downDiv_addToCartBtn:active{
    scale:0.95;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .menu_block_sectionList_item {
        margin: 3px;
        width: 235px;
        height: 340px;
    }
    .menu_block_sectionList_item_title {
        height: 55px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .menu_block_sectionList_item {
        margin: 3px;
        width: 210px;
        height: 312px;
    }
    .menu_block_sectionList_item_img{
        height: 210px;
    }
    .menu_block_sectionList_item_title {
        height: 45px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .menu_block_sectionList_item {
        margin: 3px;
        width: 200px;
        height: 290px;
    }
    .menu_block_sectionList_item_img{
        height: 190px;
    }
    .menu_block_sectionList_item_title {
        height: 45px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .menu_block_sectionList_item {
        flex-direction: row;
        margin: 3px;
        width: 100%;
        height: 155px;
        box-shadow: none;
    }
    .menu_block_sectionList_item_center{
        width:50%;
    }
    .menu_block_sectionList_item_img{
        height: 140px;
        width: 50%;
        padding: 15px 10px;
    }
    .menu_block_sectionList_item_title{
        height: 45px;
        font-size: 1.25rem;
        text-align: left;
        white-space: nowrap;
    }
    .menu_block_sectionList_item_downDiv_price{
        background: var(--accent-hover-app-color);
        color: var(--accent-app-color);
        padding: 7px 25px;
        border-radius: 20px;
        font-size: 1rem;
    }
    .menu_block_sectionList_item_downDiv_price.missing{
        display: block;
    }
    .menu_block_sectionList_item_downDiv_addToCartBtn{
        display: none;
    }
    .menu_block_sectionList_item_downDiv{
        padding: 0;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .menu_block_sectionList_item {
        flex-direction: row;
        margin: 3px;
        width: 100%;
        height: 150px;
        box-shadow: none;
    }
    .menu_block_sectionList_item_center{
        width:50%;
    }
    .menu_block_sectionList_item_img{
        height: 135px;
        width: 50%;
        padding: 15px 10px;
    }
    .menu_block_sectionList_item_title {
        height: 45px;
        font-size: 1.25rem;
        text-align: left;
        white-space: nowrap;
    }
    .menu_block_sectionList_item_downDiv_price{
        background: var(--accent-hover-app-color);
        color: var(--accent-app-color);
        padding: 7px 25px;
        border-radius: 20px;
        font-size: 1rem;
    }
    .menu_block_sectionList_item_downDiv_price.missing{
        display: block;
    }
    .menu_block_sectionList_item_downDiv_addToCartBtn{
        display: none;
    }
    .menu_block_sectionList_item_downDiv{
        padding: 0;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .menu_block_sectionList_item {
        flex-direction: row;
        margin: 3px;
        width: 100%;
        height: 150px;
        box-shadow: none;
    }
    .menu_block_sectionList_item_center{
        width:50%;
    }
    .menu_block_sectionList_item_img{
        height: 135px;
        width: 50%;
        padding: 15px 10px;
    }
    .menu_block_sectionList_item_title {
        height: 45px;
        font-size: 1.25rem;
        text-align: left;
        white-space: nowrap;
    }
    .menu_block_sectionList_item_downDiv_price{
        background: var(--accent-hover-app-color);
        color: var(--accent-app-color);
        padding: 7px 25px;
        border-radius: 20px;
        font-size: 1rem;
    }
    .menu_block_sectionList_item_downDiv_price.missing{
        display: block;
    }
    .menu_block_sectionList_item_downDiv_addToCartBtn{
        display: none;
    }
    .menu_block_sectionList_item_downDiv{
        padding: 0;
    }
}