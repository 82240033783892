@font-face {
  font-family: 'logo-font';
  src: url('/public/fonts/ClearSans-Medium.ttf');
}
html{
  font-size: 20px;
}
body{
  --background-color:#f0f0f0;
  --box-shadow-color:rgba(34, 60, 80, 0.2);
  --theme-color:#fff;
  --negative-color:#000;
  --subtext-color:#9f9f9f;
  --accent-app-color:#d55b0e;
  --modal-bkg-color:#fff;

  --near-negative-color:#6b6a68;
  --gray-button-color:#d5d5d5;
  --gray-button-hover-color:#bcbcbc;
  --hover-color: #e7e7e7;

  --deactived-opacity-color:#e4e4e4;
  --accent-hover-app-color:#d55b0e66;
  --skeleton-color:#858585;
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.6;
  background: var(--background-color);
  font-family: 'logo-font', serif;
  user-select: none;
}
body.dark{
  --background-color: #080808;
  --box-shadow-color: rgb(0 0 0 / 26%);
  --theme-color:#000;
  --negative-color:#fff;
  --subtext-color:#898989;
  --accent-app-color:#d55b0e;
  --modal-bkg-color:#141414;

  --near-negative-color:#6b6a68;
  --gray-button-color:#d5d5d5;
  --gray-button-hover-color:#bcbcbc;
  --hover-color: #1f1f1f;

  --deactived-opacity-color: #232323;
  --accent-hover-app-color:#d55b0e66;
  --skeleton-color:#858585;
}
#root{
  display: flex;
  flex-direction: column;
  align-items: center;
}
body.noscr{
  overflow-y:hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
*::selection {
  background: var(--accent-app-color);
  color: #fff;
}
h1,h2,h3,h4,h5,h6{
  color: var(--negative-color);
  margin: 0;
}
button{
  border: none;
  cursor: pointer;
  border-radius: 20px;
  user-select: none;
  -webkit-user-select: none;
}
button img, button svg{
  -webkit-user-drag: none;
}
h1{
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 8px;
}
h2{
  font-size: 1.8rem;
  font-weight: 700;
  margin: 30px 0;
}
h3{
  color: var(--negative-color);
  font-size: 1.7rem;
  font-weight: normal;
}
h4{
  color: var(--negative-color);
  font-size: 1.3rem;
  font-weight: normal;
}
center{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fill_container{
  width: 100%;
}
main{
  width: 85%;
  margin-top: 110px;
}

@keyframes skeleton-animation {
  from {
    opacity: 1;
  }
  50%{
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
  html{
    font-size: 16px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
  html{
    font-size: 14px;
  }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  html{
    font-size: 13px;
  }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
  html{
    font-size: 16px;
  }
  main{
    width: 95%;
    margin-top: 70px;
  }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
  html{
    font-size: 15px;
  }
  main{
    width: 95%;
    margin-top: 70px;
  }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
  html{
    font-size: 13px;
  }
  main{
    width: 95%;
    margin-top: 70px;
  }
}

.user_selection_allowed{
  user-select: text;
}