.map_widget_btn{
    display:flex;
    align-items:center;
    margin: 22px 10px 0 10px;
    z-index: 4;
    background:#000;
    padding: 16px;
    height: 67px;
    border-radius: 20px;
    cursor:pointer;
    text-decoration: none;
    box-shadow: 2px 1px 8px 0 rgba(34, 60, 80, 0.2);
    transition:background 0.2s ease,scale 0.2s ease;
}
.map_widget_btn:hover{
    background:#00000082;
}
.map_widget_btn:active{
    scale:0.95;
}
.map_widget_btn_img{
    height: 42px;
    -webkit-user-drag: none;
}
.map_widget_btn_txt{
    color:#fff;
    font-size:1rem;
    margin-left: 4px;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .map_widget_btn {
        padding: 0 10px;
        height: 60px;
    }
    .map_widget_btn_img {
        height: 36px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .map_widget_btn {
        padding: 0 10px;
        height: 60px;
    }
    .map_widget_btn_img {
        height: 36px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .map_widget_btn {
        padding: 0 10px;
        height: 52px;
    }
    .map_widget_btn_img {
        height: 30px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .map_widget_btn {
        padding: 0 10px;
        height: 52px;
        margin: 16px 5px 0 5px;
    }
    .map_widget_btn_img {
        height: 30px;
    }
    .map_widget_btn_txt{
        font-size:0.8rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .map_widget_btn {
        padding: 0 10px;
        height: 52px;
        margin: 16px 5px 0 5px;
    }
    .map_widget_btn_img {
        height: 30px;
    }
    .map_widget_btn_txt{
        font-size:0.8rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .map_widget_btn {
        padding: 0 10px;
        height: 52px;
        margin: 16px 5px 0 5px;
    }
    .map_widget_btn_img {
        height: 30px;
    }
    .map_widget_btn_txt{
        font-size:0.8rem;
    }
}