.app_download_block{
    border-radius: 20px;
    margin: 8px;
    padding: 0 0 0 52px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow:hidden;
}
.app_download_block.left{
    padding: 0 52px 0 0;
}
.app_download_block_imgs{
    height: 293px;
    display: flex;
    align-items: end;
    width: 312px;
}
.app_download_block_imgs_right{
    height: 293px;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    width: 312px;
}
.app_download_block_texts{
    width: 386px;
}
.app_download_block_texts_title{
    color: #fff;
    font-size: 1.65rem;
}
.app_download_block_texts_subtitle{
    color: #90cbff;
    font-size: 1rem;
}
.app_download_block_imgs_item{
    z-index: 2;
    max-height: 270px;
    -webkit-user-drag: none;
}
.app_download_block_circle{
    width: 0;
    height: 0;
}
.app_download_block_circle_body{
    width: 374px;
    height: 382px;
    position: relative;
    bottom: 312px;
    left: -102px;
    z-index: 1;
}
.app_download_block_circle_body_right{
    width: 550px;
    height: 382px;
    position: relative;
    bottom: 312px;
    right: 336px;
    z-index: 1;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .app_download_block_imgs,
    .app_download_block_imgs_right{
        height: 253px;
        width: 280px;
    }
    .app_download_block_imgs_item{
        max-height: 230px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .app_download_block_imgs,
    .app_download_block_imgs_right{
        height: 220px;
        width: 280px;
    }
    .app_download_block_imgs_item{
        max-height: 200px;
    }
    .app_download_block_circle_body {
        left: -140px;
    }
    .app_download_block_circle_body_right {
        right: 275px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .app_download_block {
        padding: 0 0 0 30px;
    }
    .app_download_block.left {
        padding: 0 30px 0 0;
    }
    .app_download_block_imgs,
    .app_download_block_imgs_right{
        height: 195px;
        width: 280px;
    }
    .app_download_block_imgs_item{
        max-height: 175px;
    }
    .app_download_block_circle_body {
        left: -180px;
        bottom: 280px;
    }
    .app_download_block_circle_body_right {
        right: 245px;
        bottom: 290px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .app_download_block {
        padding: 0 0 0 15px;
    }
    .app_download_block.left {
        padding: 0 15px 0 0;
    }
    .app_download_block_imgs,
    .app_download_block_imgs_right{
        height: 195px;
        width: 280px;
    }
    .app_download_block_imgs_item{
        max-height: 155px;
    }
    .app_download_block_circle_body {
        left: -225px;
        bottom: 270px;
    }
    .app_download_block_circle_body_right {
        right: 195px;
        bottom: 290px;
    }
    .app_download_block_texts_title{
        font-size: 1.4rem;
    }
    .app_download_block_texts_subtitle{
        font-size: 0.8rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .app_download_block {
        padding: 0 0 0 15px;
    }
    .app_download_block.left {
        padding: 0 15px 0 0;
    }
    .app_download_block_imgs,
    .app_download_block_imgs_right{
        height: 175px;
        width: 270px;
    }
    .app_download_block_imgs_item{
        max-height: 150px;
    }
    .app_download_block_circle_body {
        left: -235px;
        bottom: 255px;
    }
    .app_download_block_circle_body_right {
        right: 185px;
        bottom: 290px;
    }
    .app_download_block_texts_title{
        font-size: 1.4rem;
    }
    .app_download_block_texts_subtitle{
        font-size: 0.8rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .app_download_block {
        padding: 0 0 0 15px;
    }
    .app_download_block.left {
        padding: 0 15px 0 0;
    }
    .app_download_block_imgs,
    .app_download_block_imgs_right{
        height: 150px;
        width: 270px;
    }
    .app_download_block_imgs_item{
        max-height: 130px;
    }
    .app_download_block_circle_body {
        left: -255px;
        bottom: 255px;
    }
    .app_download_block_circle_body_right {
        right: 160px;
        bottom: 270px;
    }
    .app_download_block_texts_title{
        font-size: 1.4rem;
    }
    .app_download_block_texts_subtitle{
        font-size: 0.8rem;
    }
}