.alert_modal{
    position: fixed;
    padding: 10px 20px;
    border-radius: 20px;
    background: #00c200;
    z-index: 20;
    top: 30px;
    left: 30px;
    opacity: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alert_modal.red{
    background: #ff0000;
}
.alert_modal_icon{
    height: 30px;
    -webkit-user-drag: none;
}
.alert_modal_text{
    color: #fff;
    font-size: 1rem;
    margin-left: 5px;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .alert_modal_text{
        font-size: 1.15rem;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .alert_modal{
        padding: 5px 13px;
    }
    .alert_modal_icon{
        height: 24px;
    }
    .alert_modal_text{
        font-size: 1.1rem;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .alert_modal{
        padding: 8px 11px;
    }
    .alert_modal_icon{
        height: 24px;
    }
    .alert_modal_text{
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .alert_modal{
        left: 20px;
        padding: 6px 12px;
    }
    .alert_modal_icon{
        height: 24px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .alert_modal{
        left: 20px;
        padding: 8px 15px;
    }
    .alert_modal_icon{
        height: 24px;
    }
    .alert_modal_text{
        font-size: 1.1rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .alert_modal{
        left: 10px;
        padding: 8px 15px;
    }
    .alert_modal_icon{
        height: 24px;
    }
    .alert_modal_text{
        font-size: 1.2rem;
    }
}

.alert_anim-enter {
    left: -50px;
    opacity: 0;
}
.alert_anim-enter-active {
    left: 50px;
    opacity: 100%;
    transition: left 300ms, opacity 300ms;
}
.alert_anim-exit {
    left: 50px;
    opacity: 100%;
}
.alert_anim-exit-active {
    left: -50px;
    opacity: 0;
    transition: left 300ms, opacity 300ms;
}