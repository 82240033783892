.menu_block_sectionGroup{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.menu_block_sectionList{
    display: flex;
    flex-wrap: wrap;
    width:100%;
}
.section_title{
    position: sticky;
    top: 90px;
    z-index: 3;
    border-radius: 0 0 20px 20px;
    background: var(--accent-app-color);
    color: #fff;
    padding: 0 20px;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .section_title{
        top: 78px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .section_title{
        top: 76px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .section_title{
        top: 72px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .section_title{
        top: 65px;
        font-size: 1.7rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .section_title{
        top: 65px;
        font-size: 1.7rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .section_title{
        top: 65px;
        font-size: 1.7rem;
    }
}