.modal_bottom_left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal_bottom_right{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.modal_bottom_left_item_img{
    max-width: 100%;
    max-height: 249px;
    min-height: 227px;
}
.modal_bottom_right_item_title{
    color: var(--negative-color);
    font-size: 1.5rem;
    user-select: text;
}
.modal_bottom_right_item_about {
    color: var(--accent-app-color);
    width: 100%;
    font-size: 0.9rem;
    user-select: text;
}
.modal_bottom_right_item_delivery_available{
    font-size: 0.8rem;
    color: #ff0700;
    text-align: center;
    padding: 6px;
    border-radius: 20px;
    background: #ff07002e;
    display: none;
}
.modal_bottom_right_item_delivery_available.act{
    display: block;
}
.modal_bottom_right_item_composition{
    color: var(--subtext-color);
    width: 100%;
    margin: 11px 0;
    user-select: text;
}
.modal_bottom_right_item_additionals_list{
    width: 100%;
    margin-top: 10px;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .modal_bottom_right_item_composition{
        margin: 8px 0;
    }
    .modal_bottom_right_item_additionals_list{
        margin-top: 5px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .modal_bottom_right_item_composition{
        margin: 8px 0;
    }
    .modal_bottom_right_item_additionals_list{
        margin-top: 5px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .modal_bottom_right_item_composition{
        margin: 8px 0;
    }
    .modal_bottom_right_item_additionals_list{
        margin-top: 5px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .modal_bottom_right_item_composition{
        margin: 8px 0;
    }
    .modal_bottom_right_item_additionals_list{
        margin-top: 5px;
    }
    .modal_bottom_left,
    .modal_bottom_right{
        width: 100%;
    }
    .modal_bottom_right_item_title{
        font-size: 1.8rem;
    }
    .modal_bottom_right_item_about {
        font-size: 1.05rem;
    }
    .modal_bottom_right_item_composition{
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .modal_bottom_right_item_composition{
        margin: 8px 0;
    }
    .modal_bottom_right_item_additionals_list{
        margin-top: 5px;
    }
    .modal_bottom_left,
    .modal_bottom_right{
        width: 100%;
    }
    .modal_bottom_right_item_title{
        font-size: 1.8rem;
    }
    .modal_bottom_right_item_about {
        font-size: 1.05rem;
    }
    .modal_bottom_right_item_composition{
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .modal_bottom_right_item_composition{
        margin: 8px 0;
    }
    .modal_bottom_right_item_additionals_list{
        margin-top: 5px;
    }
    .modal_bottom_left,
    .modal_bottom_right{
        width: 100%;
    }
    .modal_bottom_right_item_title{
        font-size: 1.8rem;
    }
    .modal_bottom_right_item_about {
        font-size: 1.05rem;
    }
    .modal_bottom_right_item_composition{
        font-size: 0.9rem;
    }
}