.cart_clearall_div{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 25px;
    margin-bottom: 20px;
}
.cart_clearall_button{
    border: none;
    background: none;
    color: #ff1111;
    font-size: 1.05rem;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.15s ease;
}
.cart_clearall_button:hover{
    color: #770606;
}
.cart_total_text{
    font-size: 0.9rem;
    color: #9a9a9a;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}
.cart_items_area{
    justify-content: start;
}
.cart_empty_img{
    height: 200px;
}
.cart_empty_text{
    margin-top: 20px;
    font-size: 1.5rem;
    color: var(--negative-color);
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .cart_clearall_div{
        margin-bottom: 0;
    }
    .cart_total_text{
        margin-bottom: 0;
    }
    .cart_empty_img{
        height: 180px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .cart_clearall_div{
        margin-bottom: 0;
    }
    .cart_total_text{
        margin-bottom: 0;
    }
    .cart_empty_img{
        height: 160px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .cart_clearall_div{
        margin-bottom: 0;
    }
    .cart_total_text{
        margin-bottom: 0;
    }
    .cart_empty_img{
        height: 140px;
    }
    .cart_empty_text{
        margin-top: 5px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .cart_clearall_div{
        margin-bottom: 0;
    }
    .cart_total_text{
        margin-bottom: 5px;
    }
    .cart_empty_img{
        height: 140px;
    }
    .cart_empty_text{
        margin-top: 5px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .cart_clearall_div{
        margin-bottom: 0;
    }
    .cart_total_text{
        margin-bottom: 10px;
    }
    .cart_empty_img{
        height: 140px;
    }
    .cart_empty_text{
        margin-top: 5px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .cart_clearall_div{
        margin-bottom: 0;
    }
    .cart_total_text{
        margin-bottom: 10px;
    }
    .cart_empty_img{
        height: 120px;
    }
    .cart_empty_text{
        margin-top: 5px;
    }
}