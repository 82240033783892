.orange_button{
    border: none;
    background: var(--accent-app-color);
    border-radius: 17px;
    width: 350px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.15s ease, scale 0.15s ease;
}
.orange_button_text{
    font-size: 1rem;
    text-align: center;
    color: #fff;
}
.orange_button:hover{
    background: var(--accent-hover-app-color);
}
.orange_button.deact{
    background: var(--deactived-opacity-color);
    color:var(--negative-color);
}
.orange_button:active{
    scale:0.95;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .orange_button{
        width: 380px;
        height: 45px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .orange_button{
        width: 280px;
        height: 38px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .orange_button{
        width: 250px;
        height: 35px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .orange_button{
        width: 250px;
        height: 35px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .orange_button{
        width: 250px;
        height: 35px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .orange_button{
        width: 250px;
        height: 35px;
    }
}