.user_ordersList_item{
    width: 100%;
    box-shadow: 0 0 8px 0 var(--box-shadow-color);
    padding: 20px 36px 14px;
    background: var(--modal-bkg-color);
    margin: 17px 0;
    border-radius: 20px;
}
.user_ordersList_item_top{
    width:100%;
    display:flex;
    justify-content: space-between;
}
.user_ordersList_item_top_row{
    display:flex;
    align-items:center;
}
.user_ordersList_item_top_side{
    display:flex;
    align-items:center;
}
.user_ordersList_item_center{
    width:100%;
    display:flex;
    justify-content:center;
}
.user_ordersList_item_top_popup{
    font-size: 0.75rem;
    background: #00ac2a;
    border-radius: 20px;
    color: #fff;
    padding: 3px 10px;
    margin: 0 3px;
}
.user_ordersList_item_top_popup.red{
    background: #ff0000;
}
.user_ordersList_item_top_popup.yellow{
    background: #fff100;
    color:var(--negative-color);
}
.user_ordersList_item_top_popup.orange{
    background: var(--accent-app-color);
    color: var(--theme-color);
}
.user_ordersList_item_top_title{
    font-size: 1.25rem;
    margin-right:12px;
    color: var(--negative-color);
}
.user_ordersList_item_date{
    font-size: 0.85rem;
    color: #b7b7b7;
    text-align: left;
}
.user_ordersList_item_top_subtitle{
    font-size: 1.05rem;
    color: var(--negative-color);
}
.user_ordersList_item_status_descr{
    font-size: 0.9rem;
    color: var(--accent-app-color);
    text-align: left;
}
.user_ordersList_item_orderInner{
    width:100%;
    margin-top: 20px;
}
.user_ordersList_item_cust_price{
    font-size: 0.9rem;
    font-weight: 700;
}
.user_ordersList_item_cust_side{
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .user_ordersList_item {
        margin: 5px 0;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .user_ordersList_item {
        margin: 5px 0;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .user_ordersList_item {
        margin: 5px 0;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .user_ordersList_item {
        margin: 5px 0;
    }
    .user_ordersList_item_orderInner{
        margin: 20px 0;
    }
    .user_ordersList_item_top{
        flex-direction: column;
    }
    .user_ordersList_item_top_title {
        white-space: nowrap;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .user_ordersList_item {
        margin: 3px 0;
    }
    .user_ordersList_item_orderInner{
        margin: 20px 0;
    }
    .user_ordersList_item_top{
        flex-direction: column;
    }
    .user_ordersList_item_top_title {
        white-space: nowrap;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .user_ordersList_item {
        margin: 3px 0;
        padding: 20px 15px 14px;
    }
    .user_ordersList_item_orderInner{
        margin: 20px 0;
    }
    .user_ordersList_item_top{
        flex-direction: column;
    }
    .user_ordersList_item_top_title {
        white-space: nowrap;
    }
}