.modal_additional_item{
    width:100%;
    display:flex;
    justify-content: space-between;
    padding: 7px 15px;
    align-items:center;
    cursor:pointer;
    transition:background 0.2s ease,scale 0.2s ease;
}
.modal_additional_item.require{
    opacity:50%;
}
.modal_additional_item:hover{
    background:var(--hover-color);
}
.modal_additional_item:active{
    scale:0.98;
}

.modal_additional_item_left{
    display:flex;
    align-items:center;
}
.modal_additional_item_img{
    --size:49px;
    height: var(--size);
    width: var(--size);
    object-fit: contain;
    margin-right: 7px;
    -webkit-user-drag: none;
}
.modal_additional_item_name{
    font-size: 1rem;
    color:var(--negative-color);
}
.modal_additional_item_price{
    font-size: 0.7rem;
    color: var(--subtext-color);
    margin-left: 10px;
}
.modal_additional_item_checkimg{
    width: 36px;
    opacity:0;
    -webkit-user-drag: none;
}
.modal_additional_item_check{
    --size-additional-item-check:30px;
    border-radius: 24px;
    border: 1px solid var(--subtext-color);
    width: var(--size-additional-item-check);
    height: var(--size-additional-item-check);
    display: flex;
    justify-content: center;
    align-items: center;
    transition:background 0.2s ease;
}
.modal_additional_item_check.act{
    background:var(--accent-app-color);
}
.modal_additional_item_check.act .modal_additional_item_checkimg{
    opacity:100%;
}

.modal_bottom_right_item_additionals_list_title{
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 11px;
    color:var(--negative-color);
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .modal_bottom_right_item_additionals_list_title{
        margin-bottom: 5px;
    }

    .modal_additional_item {
        padding: 2px 5px;
    }
    .modal_additional_item_img {
        --size:40px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .modal_additional_item {
        padding: 2px 5px;
    }
    .modal_additional_item_img {
        --size:40px;
    }
    .modal_additional_item_check{
        --size-additional-item-check:25px;
    }
    .modal_additional_item_checkimg{
        width: 30px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .modal_additional_item {
        padding: 2px 5px;
    }
    .modal_additional_item_img {
        --size:40px;;
    }
    .modal_additional_item_check{
        --size-additional-item-check:25px;
    }
    .modal_additional_item_checkimg{
        width: 30px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .modal_additional_item {
        padding: 2px 5px;
    }
    .modal_additional_item_img {
        --size:40px;
    }
    .modal_additional_item_check{
        --size-additional-item-check:25px;
    }
    .modal_additional_item_checkimg{
        width: 30px;
    }
    .modal_additional_item_name{
        font-size: 1.15rem;
    }
    .modal_additional_item_price{
        font-size: 0.8rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .modal_additional_item {
        padding: 2px 5px;
    }
    .modal_additional_item_img {
        --size:40px;
    }
    .modal_additional_item_check{
        --size-additional-item-check:25px;
    }
    .modal_additional_item_checkimg{
        width: 30px;
    }
    .modal_additional_item_name{
        font-size: 1.15rem;
    }
    .modal_additional_item_price{
        font-size: 0.8rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .modal_additional_item {
        padding: 2px 5px;
    }
    .modal_additional_item_img {
        --size:40px;
    }
    .modal_additional_item_check{
        --size-additional-item-check:25px;
    }
    .modal_additional_item_checkimg{
        width: 30px;
    }
    .modal_additional_item_name{
        font-size: 1.15rem;
    }
    .modal_additional_item_price{
        font-size: 0.8rem;
    }
}