input{
    border: 1px solid var(--near-negative-color);
    background: var(--background-color);
    font-size: 1.25rem;
    color: var(--negative-color);
    padding: 15px;
    border-radius: var(--border-r,20px);
    margin: 4px 0;
    width: 380px;
}
.fill_space{
    width: 100%;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    input{
        width: 350px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    input{
        --border-r:15px;
        margin: 4px 0;
        padding: 12px;
        width: 330px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    input{
        --border-r:15px;
        margin: 3px 0;
        padding: 12px;
        width: 300px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    input{
        --border-r:15px;
        margin: 3px 0;
        padding: 12px;
        width: 100%;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    input{
        --border-r:15px;
        margin: 3px 0;
        padding: 12px;
        width: 100%;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    input{
        --border-r:15px;
        margin: 3px 0;
        padding: 12px;
        width: 100%;
    }
}