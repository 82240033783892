a{
    color: var(--accent-app-color);
    cursor: pointer;
    padding: 2px;
    border-radius: 20px;
    transition: background 0.3s ease,opacity 0.3s ease;
}
a:hover{
    background: var(--accent-hover-app-color);
}
a:active{
    opacity: 0.8;
}