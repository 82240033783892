.operating_mode{
    width: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, rgba(181, 150, 255, 1) 0%, rgba(198, 174, 255, 1) 100%);
    border-radius: 20px;
    overflow:hidden;
}
.operating_mode_side{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:50%;
    padding: 36px 0;
}
.operating_mode_icon{
    height: 181px;
    z-index: 2;
    -webkit-user-drag: none;
}
.operating_mode_img{
    width: 50%;
    height: 512px;
    object-fit: cover;
    z-index: 2;
}
.operating_mode_side_back{
    width: 880px;
    height: 711px;
    position: relative;
    bottom: 417px;
    right: 549px;
    z-index: 1;
}
.operating_mode_title{
    font-size: 2.5rem;
    text-align:center;
    z-index: 1;
}
.operating_mode_subtitle{
    font-size: 1.25rem;
    color: #fff;
    text-align: center;
    z-index:1;
}
.app_download_block_circle{
    width: 0;
    height: 0;
}
.operating_mode_title.skeleton{
    width: 80%;
    color: transparent;
    background: var(--skeleton-color);
    border-radius: 20px;
    animation: 0.7s ease-in infinite skeleton-animation;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .operating_mode_img {
        height: 350px;
    }
    .operating_mode_icon {
        height: 150px;
    }
    .operating_mode_title {
        font-size: 2.4rem;
    }
    .operating_mode_subtitle {
        font-size: 1.22rem;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .operating_mode{
        flex-direction: column;
    }
    .operating_mode_side,
    .operating_mode_img{
        width: 100%;
    }
    .operating_mode_img {
        height: 300px;
    }
    .operating_mode_icon {
        height: 150px;
    }
    .operating_mode_title {
        font-size: 2.4rem;
    }
    .operating_mode_subtitle {
        font-size: 1.22rem;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .operating_mode{
        flex-direction: column;
    }
    .operating_mode_side,
    .operating_mode_img{
        width: 100%;
    }
    .operating_mode_side{
        padding: 20px 0;
    }
    .operating_mode_img {
        height: 250px;
    }
    .operating_mode_icon {
        height: 130px;
    }
    .operating_mode_title {
        font-size: 2.4rem;
    }
    .operating_mode_subtitle {
        font-size: 1.22rem;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .operating_mode{
        flex-direction: column;
    }
    .operating_mode_side,
    .operating_mode_img{
        width: 100%;
    }
    .operating_mode_side{
        padding: 20px 0;
    }
    .operating_mode_img {
        height: 250px;
    }
    .operating_mode_icon {
        height: 115px;
    }
    .operating_mode_title {
        font-size: 2rem;
    }
    .operating_mode_subtitle {
        font-size: 1rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .operating_mode{
        flex-direction: column;
    }
    .operating_mode_side,
    .operating_mode_img{
        width: 100%;
    }
    .operating_mode_side{
        padding: 20px 0;
    }
    .operating_mode_img {
        height: 250px;
    }
    .operating_mode_icon {
        height: 115px;
    }
    .operating_mode_title {
        font-size: 2rem;
    }
    .operating_mode_subtitle {
        font-size: 1rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .operating_mode{
        flex-direction: column;
    }
    .operating_mode_side,
    .operating_mode_img{
        width: 100%;
    }
    .operating_mode_side{
        padding: 20px 0;
    }
    .operating_mode_img {
        height: 250px;
    }
    .operating_mode_icon {
        height: 115px;
    }
    .operating_mode_title {
        font-size: 2rem;
    }
    .operating_mode_subtitle {
        font-size: 1rem;
    }
}

.color_green {
    color: #00af0c;
}
.color_red{
    color: #ff0000;
}
.color_black{
    color: #000;
}