.header{
    --h-padding:50px;
    width:100%;
    height:80px;
    background:var(--modal-bkg-color);
    box-shadow: 2px 1px 8px 0 var(--box-shadow-color);
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    padding: 0 var(--h-padding);
    position:fixed;
    top:0;
    z-index:10;
    transition: background 0.3s ease;
}
.header > div:first-child,
.header > div:last-child{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.header_right_skeleton{
    width: 200px;
    height: 50px;
    color: transparent;
    background: var(--skeleton-color);
    border-radius: 20px;
    animation: 0.7s ease-in infinite skeleton-animation;
}
.iblogo{
    height: 57px;
    cursor: pointer;
}
.iblogo_txt{
    font-family: 'logo-font', serif;
    font-size: 1.6rem;
    color: var(--negative-color);
}
.header_left_nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 38px;
}
.header_left_nav div{
    color: var(--near-negative-color);
    font-size: 1rem;
    margin: 0 20px;
    cursor:pointer;
}
.header_left_nav div:hover{
    color: var(--gray-button-hover-color);
}

.header_right_singupbtn{
    font-size: 1rem;
    border: none;
    background: var(--accent-app-color);
    padding: 13px 23px;
    border-radius: 32px;
    font-weight: 700;
    margin: 0 15px;
    color:var(--theme-color);
    cursor: pointer;
    transition:scale 0.15s ease,background 0.15s ease;
}
.header_right_singupbtn:hover{
    background: var(--accent-hover-app-color);
}
.header_right_singupbtn:active{
    scale:0.95;
}

.header_orderbag_button{
    width: 60px;
    border: none;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 15px;
    background: none;
    border-radius: 20px;
    cursor: pointer;
    scale: 1;
    transition: background 0.2s ease, scale 0.2s ease;
}
.header_orderbag_button:hover{
    background: var(--hover-color);
}
.header_orderbag_button:active{
    scale:0.95;
}
.header_orderbag_button_img{
    width: 45px;
}
.header_orderbag_button_price{
    color: #fff;
    background: red;
    padding: 4px 6px;
    border-radius: 20px;
    font-size: 0.55rem;
    position: relative;
    top: -7px;
    right: 22px;
}

.header_menu_parent{
    position: relative;
}
.header_account_button{
    border: none;
    background: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background 0.2s ease, scale 0.2s ease;
}
.header_account_button:hover{
    background: var(--hover-color);
}
.header_menu{
    display: none;
    position: absolute;
    overflow: hidden;
    background:var(--modal-bkg-color);
    top: 100%;
    left: 0;
    right: 0;
    z-index: 20;
    width: 232px;
    border-radius: 20px;
    font-family: Evolventa_regular, serif;
    box-shadow: 2px 1px 8px 0 rgba(34, 60, 80, 0.2);
}
.header_account_button:focus-within .header_menu{
    display: block;
}
.header_account_button:active{
    scale:0.95;
}
.header_account_button_text{
    font-size: 1.1rem;
    font-family: 'logo-font', serif;
    color: var(--negative-color);
}
.header_account_button_counter{
    font-size: 1rem;
    background: #f00;
    color: var(--theme-color);
    border-radius: 20px;
    padding: 0 11px;
    margin-left: 7px;
    cursor: pointer;
    display: none;
}
.header_account_button_arrow{
    width: 33px;
}
.header_account_button_avatar{
    width: 56px;
}

.header_menu_item{
    border: none;
    background: none;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.15s ease, scale 0.15s ease;
}
.header_menu_item:hover{
    background:var(--hover-color);
}
.header_menu_item:active{
    scale:0.96;
}
.header_menu_item_img{
    width: 60px;
}
.header_menu_item_text{
    color: var(--negative-color);
    font-size: 1.25rem;
    font-family: 'logo-font', serif;
}

@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .header{
        --h-padding: 40px;
        height:75px;
    }
    .header_menu{
        width: 190px;
    }
    .header_account_button_avatar{
        width: 54px;
    }
    .iblogo_txt{
        font-size: 1.8rem;
    }
    .header_right_skeleton{
        width: 170px;
        height: 45px;
    }
    .header_menu_item_img{
        width: 50px;
    }
    .header_menu_item_text{
        font-size: 1.1rem;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .header{
        --h-padding: 30px;
        height: 70px;
    }
    .header_menu{
        width: 180px;
    }
    .header_account_button_avatar{
        width: 50px;
    }
    .iblogo{
        height: 50px;
    }
    .iblogo_txt{
        font-size: 1.9rem;
    }
    .header_right_skeleton{
        width: 155px;
        height: 40px;
    }
    .header_menu_item_img{
        width: 45px;
    }
    .header_menu_item_text{
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .header{
        --h-padding: 20px;
        height: 70px;
    }
    .header_menu{
        width: 170px;
    }
    .header_account_button_avatar{
        width: 50px;
    }
    .iblogo{
        height: 48px;
    }
    .iblogo_txt{
        font-size: 1.9rem;
    }
    .header_left_nav{
        display: none;
    }
    .header_left{
        margin-left: 20px;
    }
    .header_right{
        margin-right: 20px;
    }
    .header_account_button_text{
        font-size: 1.3rem;
    }
    .header_right_skeleton{
        width: 145px;
        height: 40px;
    }
    .header_menu_item_img{
        width: 45px;
    }
    .header_menu_item_text{
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .header{
        --h-padding: 20px;
        height: 60px;
    }
    .header_menu{
        width: 160px;
    }
    .header_account_button_avatar{
        width: 40px;
    }
    .iblogo{
        height: 43px;
    }
    .iblogo_txt{
        font-size: 1.45rem;
    }
    .header_left_nav{
        display: none;
    }
    .header_left{
        margin-left: 15px;
    }
    .header_right{
        margin-right: 15px;
    }
    .header_account_button_text{
        display: none;
    }
    .header_orderbag_button_img{
        width: 35px;
    }
    .header_right_skeleton{
        width: 130px;
        height: 32px;
    }
    .header_menu_item_img{
        width: 42px;
    }
    .header_menu_item_text{
        font-size: 1rem;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .header{
        --h-padding: 15px;
        height: 60px;
    }
    .header_menu{
        width: 165px;
    }
    .header_account_button_avatar{
        width: 40px;
    }
    .iblogo{
        height: 42px;
    }
    .iblogo_txt{
        font-size: 1.5rem;
    }
    .header_left_nav{
        display: none;
    }
    .header_left{
        margin-left: 14px;
    }
    .header_right{
        margin-right: 14px;
    }
    .header_account_button_text{
        display: none;
    }
    .header_orderbag_button_img{
        width: 35px;
    }
    .header_right_skeleton{
        width: 125px;
        height: 32px;
    }
    .header_menu_item_img{
        width: 44px;
    }
    .header_menu_item_text{
        font-size: 1.1rem;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .header{
        --h-padding: 10px;
        height: 60px;
    }
    .header_menu{
        width: 165px;
    }
    .header_account_button_avatar{
        width: 40px;
    }
    .iblogo{
        height: 42px;
    }
    .iblogo_txt{
        font-size: 1.5rem;
    }
    .header_left_nav{
        display: none;
    }
    .header_left{
        margin-left: 8px;
    }
    .header_right{
        margin-right: 8px;
    }
    .header_account_button_text{
        display: none;
    }
    .header_orderbag_button_img{
        width: 35px;
    }
    .header_right_skeleton{
        width: 110px;
        height: 30px;
    }
    .header_menu_item_img{
        width: 45px;
    }
    .header_menu_item_text{
        font-size: 1.3rem;
    }
}

.color_orange{
    color:var(--accent-app-color);
}