.interactive_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 11px 0;
    width: 100%;
}
.subtext_alert{
    color: var(--subtext-color);
    width: 100%;
    margin: 11px 0;
    text-align: center;
}

.opacity_anim_controller{
    opacity: 1;
    transition: opacity 0.3s ease;
}
.opacity_anim_controller.pending{
    opacity: 0.3;
}