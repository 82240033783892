.modal_bottom_button{
    border: none;
    background: var(--accent-app-color);
    border-radius: 17px;
    width: 357px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.15s ease, scale 0.15s ease;
}
.modal_bottom_button_text{
    color: #fff;
    font-size: 1.25rem;
    text-align: center;
}
.modal_bottom_button:hover{
    background: var(--accent-hover-app-color);
}
.modal_bottom_button.deact{
    background: var(--deactived-opacity-color);
}
.modal_bottom_button.deact .modal_bottom_button_text{
    color:var(--negative-color);
}
.modal_bottom_button:active{
    scale:0.95;
}


@media screen and (min-width: 1023px) and (max-width: 1440px) {
    .modal_bottom_button{
        height: 55px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .modal_bottom_button{
        width: 340px;
        height: 48px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .modal_bottom_button{
        width: 310px;
        height: 45px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .modal_bottom_button{
        width: 310px;
        height: 40px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .modal_bottom_button{
        width: 310px;
        height: 40px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .modal_bottom_button{
        width: 310px;
        height: 40px;
    }
}