.change_theme_button{
    background-color: var(--background-color);
    padding: 0 10px;
    margin: 0 10px;
}
.change_theme_button:hover{
    opacity: 0.95;
}
.change_theme_button:active{
    scale:0.95;
}
.change_theme_button_img{
    height: 35px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .change_theme_button_img{
        height: 30px;
    }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
    .change_theme_button_img{
        height: 30px;
    }
}
@media screen and (min-width: 424px) and (max-width: 601px) {
    .change_theme_button{
        margin: 0 6px;
    }
    .change_theme_button_img{
        height: 25px;
    }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
    .change_theme_button{
        margin: 0 6px;
    }
    .change_theme_button_img{
        height: 25px;
    }
}
@media screen and (min-width: 0px) and (max-width: 376px) {
    .change_theme_button{
        margin: 0 6px;
    }
    .change_theme_button_img{
        height: 25px;
    }
}